.kiwi-messagelist-message--modern {
  border-left: 7px solid transparent;
  display: flex;
  margin: 0 0 0 20px;
  margin-left: 0;
  transition: border-colour 0.2s, background-color 0.2s;
}
.kiwi-messagelist-modern-left {
  user-select: none;
  position: relative;
  display: flex;
  width: 50px;
}
.kiwi-messagelist-awaystatus {
  width: 10px;
  top: 4px;
  right: 2px;
  height: 10px;
  position: absolute;
}
.kiwi-messagelist-message--modern .kiwi-avatar {
  height: 40px;
  width: 40px;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorfirst {
  padding-top: 10px;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat {
  border-top: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-modern-right {
  padding-top: 0;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-topic {
  margin: 20px 20px 20px 20px;
  padding: 10px 20px;
  width: auto;
  box-sizing: border-box;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-topic .kiwi-messagelist-modern-left {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat .kiwi-avatar {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-top {
  display: none;
}
/* Connection styling */
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0;
  opacity: 0.8;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-time,
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-body {
  padding: 0 20px;
  margin: 0 auto;
  display: inline-block;
  font-weight: 600;
  font-size: 0.8em;
  opacity: 0.8;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-body {
  white-space: pre-wrap;
  word-wrap: break-word;
  display: block;
  margin-left: 0;
  margin-bottom: 10px;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-body a {
  word-break: break-all;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-modern-right {
  margin-left: 5px;
  padding-top: 0;
  width: 100%;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-top > div {
  margin-right: 10px;
  padding: 0;
  display: inline-block;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-realname {
  cursor: pointer;
}
.kiwi-messagelist-message--modern .kiwi-messagelist-time {
  font-size: 0.8em;
  font-weight: 400;
  opacity: 0.6;
}
.kiwi-messagelist-message-traffic .kiwi-messagelist-body {
  margin-bottom: 0;
}
.kiwi-messagelist-message-traffic .kiwi-messagelist-modern-left,
.kiwi-messagelist-message-traffic .kiwi-messagelist-top {
  display: none;
}
.kiwi-messagelist-message--modern.kiwi-messagelist-message-traffic {
  margin-right: 0;
  padding-left: 60px;
}
.kiwi-messagelist-message-error {
  padding: 10px 0;
  font-weight: 600;
  line-height: normal;
}
.kiwi-messagelist-message-error .kiwi-messagelist-top {
  display: none;
}
.kiwi-messagelist-message-error .kiwi-messagelist-body {
  margin-bottom: 0;
}
@media screen and (max-width: 769px) {
  .kiwi-messagelist-message--modern .kiwi-messagelist-modern-left {
    width: 10px;
  }
  .kiwi-messagelist-message--modern.kiwi-messagelist-message-privmsg .kiwi-messagelist-modern-left,
  .kiwi-messagelist-message-notice .kiwi-messagelist-modern-left {
    display: none;
  }
  .kiwi-messagelist-message--modern .kiwi-messagelist-modern-right {
    margin-left: 0;
  }
  .kiwi-messagelist-message--modern {
    margin: 0;
  }
  .kiwi-messagelist-message--modern.kiwi-messagelist-message-connection {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    border: none;
    width: 100%;
    border-radius: 0;
  }
  .kiwi-messagelist-message--modern.kiwi-messagelist-message-connection .kiwi-messagelist-body {
    line-height: 50px;
    font-weight: 600;
    padding: 0 10px;
  }
  .kiwi-messagelist-message-action .kiwi-messagelist-modern-left {
    display: none;
  }
  .kiwi-messagelist-message--modern .kiwi-avatar {
    display: none;
  }
  .kiwi-messagelist-message--modern.kiwi-messagelist-message-traffic {
    padding-left: 10px;
  }
  .kiwi-messagelist-message--modern.kiwi-messagelist-message-topic {
    margin: 0 15px 20px 15px;
  }
}
