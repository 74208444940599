






































































































































.kiwi-settings-advanced {
    width: 100%;
}

.kiwi-settings-advanced-table .u-input {
    border-bottom: 2px solid red;
    height: auto;
}

.kiwi-settings-advanced-table label {
    margin: 0;
}

.kiwi-settings-advanced-table td {
    height: 30px;
}

.kiwi-settings-advanced-table td:nth-child(2) {
    min-width: 100px;
}

.kiwi-settings-advanced-table td:nth-child(3) {
    min-width: 350px;
}

.kiwi-settings-advanced tr.kiwi-advanced-setting--modified {
    font-weight: 900;
}

.kiwi-settings-advanced .u-table td .u-input {
    height: 30px;
}

.kiwi-settings-advanced-filter {
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    padding: 0 0 5px 0;
}

.kiwi-settings-advanced-filter-container {
    position: relative;
    max-width: 224px;
}

.kiwi-settings-advanced-filter-container input::-ms-clear {
    display: none;
}

.kiwi-settings-advanced-filter-container .fa-search,
.kiwi-settings-advanced-filter-container .fa-times {
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 10;
    cursor: default;
}

.kiwi-settings-advanced-filter-container .fa-times {
    cursor: pointer;
}

.kiwi-settings-advanced-filter label {
    font-weight: 600;
}

.kiwi-settings-advanced .u-form .kiwi-settings-advanced-filter .u-input {
    display: inline-block;
    border: 1px solid #000;
    height: 40px;
    padding: 0 10px;
}

.kiwi-settings-advanced-notice {
    text-align: center;
    padding: 10px 0;
    margin: 5px 0 15px 0;
    font-weight: 900;
}

.kiwi-settings-advanced .kiwi-settings-advanced-noresult {
    width: 100%;
    margin: 50px 0 30px 0;
    text-align: center;
    font-weight: 900;
    font-size: 1em;
}

@media screen and (max-width: 600px) {
    .kiwi-settings-advanced .u-form {
        overflow-x: scroll;
    }
}

